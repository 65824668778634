<template>
  <div class="modals-wrapper">
    <b-modal id="seeker-login" ref="seeker-login" title="Jobseeker login" @show="resetModal" hide-footer no-close-on-backdrop>
      <v-app>
        <div class="container logWrapper modal-bg">
          <div class="col-lg-12">
            <b-alert style="margin-top:-37px;" variant="success" :show="email_status!=8&&registerd" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Successfully registered!</small></P><P class="mb-0 text-center" style="font-size:13px;opacity:0.9;" v-if="this.$route.params.email_status"><small>Verify your email & login</small></P><P class="mb-0 text-center" v-if="this.$route.params.email_status" style="font-size:12px;opacity:0.6;"><small>Verification link sent to your email id.Check inbox / spam folder </small></P></b-alert>
            <form ref="login-form" class="login-form" @submit="userlogin">
              <div label-for="input-1">
                <v-text-field type="text" v-model="login.email" @input="validate.emailValidation(login.email, 'email')"  outlined><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                <div class="error-feedback" v-if="(typeof validate.response.email != 'undefined' && !validate.response.email.val)">
                  {{ validate.response.email.msg }}
                </div>
              </div>

              <div label-for="login-password" class="mt-6">
                <v-text-field type="password" v-model="login.password" @input="validate.passwordValidation(login.password, 'password')" outlined><template #label>Password<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  <div class="error-feedback" v-if="(typeof validate.response.password != 'undefined' &&!validate.response.password.val)">
                    {{ validate.response.password.msg }}
                  </div>
              </div>
              <div class="error-feedback" v-if="auth_error">{{auth_msg}}</div>
              <div class="error-feedback" v-if="notverified_error">Email has not been verified. <span @click="sendVerification()" style="color:#007bff;cursor:pointer">Click here to send verification</span></div>
              <div class="success-feedback" v-if="success_msg">{{success_msg}}</div>
              <div class="form-group text-center m-6">
                <b-button type="submit" class="h-login" data-sitekey="6LeObm4aAAAAAPTSP-oHe0Tv8aM7DUEqeFABnGpQ" variant="primary">Login</b-button>
              </div>
            </form>
            <div class="row">
              <div class="col-lg-12 text-secondary text-center mt-2 p-0">
                Not a member?
                <a class="text-decoration-none" :href="'/jobseeker/register?src='+this.$route.name+'&redirect_from='+this.$route.params.job"><strong> Create an account</strong></a>
              </div>
              <div class="col-lg-12 text-center">
                <small class="mb-0 lgtTxt"><a href="/privacy" target="_blank" class="flink text-decoration-none">Privacy Policy </a>|<a href="/terms" target="_blank" class="flink text-decoration-none"> Terms &amp; Conditions</a></small>
                <hr>
                <p class="text-center text-secondary m-auto">Are you an employer?<a class="text-decoration-none" href="/recruit/register"> Register free</a></p>
              </div>
            </div>
          </div>
        </div>
      </v-app>
    </b-modal>    
    <b-modal id="seeker-register" ref="seeker-register" title="Register & Apply for jobs" @show="resetModal" hide-footer no-close-on-backdrop>
      <v-app>
        <div class="container logWrapper modal-bg pt-0">
          <div class="col-lg-12 text-secondary text-right pt-0">
            Already registered? <span class="text-primary" style="color: 0054ad;cursor: pointer;" @click="openModal('login')">Login</span>
          </div>
          <div class="col-lg-12">
            <v-form ref="register_form" v-model="valid" lazy-validation>
              <div label-for="register-email" v-click-outside="hideEmailExtensions">
                <v-text-field type="text" v-model="register.email" @keyup="getExtension" :rules="emailRules" :error-messages="email_msg" outlined><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                <div class="email-dropdown-list-container" v-show="showExtensions">
                  <ul class="email-dropdown-list">
                    <li tabindex="-1" data-dropdown-item-index= index class="email-dropdown-item" v-for="(domain,index) in selectedDomains" :key="index" @click="setExtension(domain)">
                      <span class="email-dropdown-item-domain">@{{domain}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div label-for="name-input" class="mt-4">
                <v-text-field type="text" v-model="register.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined><template #label>Full name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                <div class="error-feedback" v-if ="(typeof validate.response.name != 'undefined' && !validate.response.name.val)">
                  {{ validate.response.name.msg }}
                </div>
              </div>
              <div label-for="register-mobile" class="mt-4">
                <v-text-field type="number" v-model="register.mobile" outlined :rules="mobileRules" :error-messages="mobile_msg"><template #label>Mobile<span class="red--text"><strong>* </strong></span></template></v-text-field>
              </div>
              <div label-for="register-password" class="mt-4">
                <v-text-field :append-icon="show1 ? $icons.eye : $icons.eyeOff" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1"  v-model="register.password" :rules="[v => !!v || 'Enter valid password']" :error-messages="pass_msg" outlined><template #label>Password<span class="red--text"><strong>* </strong></span></template></v-text-field>
              </div>
              <div label-for="register-jobrole" class="mt-4">
                <v-autocomplete v-model="register.jobrole" :items="jobrole" item-value="value" item-text="text" :rules="jobroleRules" clearable chips outlined multiple deletable-chips><template #label>Jobrole<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
              </div>
              <div label-for="register-experience" class="mt-4">
                <v-autocomplete id="label-experience" :items="exp.options" item-value="value" item-text="text" :searchable="true" v-model="experience" @change="showmore" :rules="[v => !!v || 'Select experience']" clearable outlined><template #label>Experience<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
              </div>

              <div label-for="register-qualification" v-show="moreinfo && experience" class="mt-4">
                <v-autocomplete v-model="register.highest_qualification" :searchable="true" :items="courses" item-text="text" item-value="id" @change="getSpecification" :rules="moreinfo?[v => (!!v) || 'Select highest qualification']:[]" :class="{'is-invalid':(can_register != 0 && !register.highest_qualification && moreinfo)}" outlined>
                  <template #label>Highest qualification<span class="red--text"><strong>* </strong></span></template>
                  <template slot="item" slot-scope="data">
                    <template v-if = typeof(data.item) != 'object'>
                      <v-list-item-content v-text="data.item.text"/>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"/>
                        <v-list-item-sub-title v-html="data.item.group"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>

              <div label-for="register-specialisation" v-show="speci" class="mt-4">
                <v-autocomplete id="label-specialisation" v-model="register.specialisation" v-show="speci" :items="spec" item-value="value" item-text="text" :rules="speci?[v => (!!v) || 'Enter valid password']:[]" @change="regedu" outlined><template #label>Specialisation<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
              </div>

              <div v-show="more">
                <div label-for="register-percentage" class="mt-4">
                  <v-text-field id="label-percentage" v-model="register.highest_qualification_percentage" type="number" :rules="more?[v => (!!v) || 'Enter highest qualification %']:[]" outlined><template #label>Highest qualification %<span class="red--text"><strong>* </strong></span></template></v-text-field>
                </div>

                <div label-for="register-passout" class="mt-4">
                  <v-autocomplete id="label-passout" v-model="register.passout_year" :items="years" item-value="value" item-text="text" :rules="more?[v => (!!v) || 'Select year of passout']:[]" outlined><template #label>Year of passout<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                </div>
              </div>

              <div label-for="register-state" class="mt-4">  
                <v-autocomplete v-model="register.city" :items="locations" item-text="text" item-value="id" :rules="[v => !!v || 'Select city']" clearable outlined> 
                  <template #label>Current city<span class="red--text"><strong>* </strong></span></template>
                  <template slot="item" slot-scope="data">
                    <template v-if = typeof(data.item) != 'object'>
                      <v-list-item-content v-text="data.item.text"/>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.text"/>
                        <v-list-item-sub-title v-html="data.item.group"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>

              <div label-for="check-sign" class="mt-4">
                <b-form-checkbox id="checkbox-1" name="checkbox-1" value="true" v-model="subscribe" unchecked-value='false' class="text-secondary">
                  <span>I hereby subscribe to newsletters and job updates from Hireclap</span>
                </b-form-checkbox>
              </div>

              <div class="form-group text-center m-6">
                <b-button type="submit" class="mr-3 mt-3 btn-signup" style="color:#fff !important" variant="primary" v-if="!isSigning" v-on:click.prevent="postReg">Register</b-button>
                <b-button type="submit" class="mr-3 mt-3 btn-signup"  style="cursor: no-drop;color:#fff !important;font-size:10px;" variant="primary" v-if="isSigning" disabled>Registering...</b-button>
                <div class="row m-2">
                  <div class="col-lg-12 text-center">
                    <small class="mb-0 lgtTxt"><a href="/privacy" target="_blank" class="flink text-decoration-none">Privacy Policy </a>|<a href="/terms" target="_blank" class="flink text-decoration-none"> Terms &amp; Conditions</a></small>
                    <hr>
                    <p class="text-center text-secondary m-auto">Are you an employer?<a class="text-decoration-none" href="/recruit/register"> Register free</a></p>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </v-app>
    </b-modal>  
    <!-- <VisumeModal :visume="null" :email_status="email_status" :open="visumeModal.open"/> -->
  </div>
</template>

<script>
import { BModal,BButton } from 'bootstrap-vue'

// const VisumeModal = () => import(/* webpackPrefetch: true */ "@/components/VisumeModal");
import Actions from '../libraries/apiActions.js';
import validation from '../libraries/validFormat' ;
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
import '../assets/hire.css';
import {eventBus} from '../main';


export default {
  name: "Modals",
  components: {
         BModal,BButton

    // VisumeModal
  },
  props: {
    open: String,
    src: String,
    redirect_from: String
  },
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
    eventBus.$on('modal',(data) =>{
      if(data == "login"){
        this.openModal('login');
      }
    });
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    })
  },
  beforeMount(){
    this.getYears();
  },
  data() {
    return {
      action: new Actions(),
      validate: new validation(),
      valid: true,
      login: {
        email: '',
        password: '',
      },
      visumeModal:{
        open:'exit',
      },
      errors: [],
      auth_error: false,
      success_msg: false,
      notverified_error: false,
      can_login: 0,
      isOpen: this.open,
      spec:[],
      states:[],
      sublocations:[],
      subCity:false,
      more:false,
      moreinfo:false,
      speci:false,
      metro_cities:[],
      exp: {
        options: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 6, text: "06 Months" },
          { value: 12, text: "1 Year" },
          { value: 24, text: "2 year" },
          { value: 36, text: "3 year" },
          { value: 48, text: "4 year" },
          { value: 60, text: "5 year" },
          { value: 72, text: "6 year" },
          { value: 84, text: "6+ year" },
        ],
      },
      register: {
        name: '',
        email: null,
        password: '',
        mobile: '',
        jobrole: [],
        experience: "",
        highest_qualification: "",
        specialisation: "",
        highest_qualification_percentage: null,
        passout_year: null,
        city: "",
        sub_location: null,
        resume: null,
        src: null,
        redirect_from: null
      },
      experience:"",
      can_register:0,
      mobile_error:false,
      pass_error:false,
      email_error:false,
      mobile : null,
      email : null,
      mobile_msg : '',
      pass_msg : '',
      email_msg : '',
      subscribe:true,
      resume:null,
      domains: [
        "gmail.com",
        "yahoo.com",
        "yahoo.co.in",
        "yahoo.in",
        "rediffmail.com",
        "outlook.com",
        "hotmail.com",
        "ymail.com",
        "live.com",
        "mail.com",
        "rocketmail.com",
        "aol.com",
        "in.com",
        "sify.com"
      ],
      selectedDomains:[],
      showExtensions:false,
      years:[],
      seeker:null,
      isSigning:false,
      isMobile:false,
      show1:false,
      email_status:null,
      registerd:false,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number',
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      jobroleRules:[
        v => v.length!=0 || 'Select minimum 1 preferred job role',
        v => v.length<4 || 'Select maximum 3 preferred job role',
      ],
    };
  },
  methods: {
    sendVerification: function() {
        this.notverified_error = false;
        this.action.sendVerification(this.login).then((res) => {
          if(res.status) {
           this.success_msg = "Verification email has been send"
          } else {
            this.error_msg = res.error
          }
          }).catch((err) => {
          if(err){
            console.log(err);
            this.error_msg = err.error;
          }
        });
    },
    openModal: function (type = "") {
      this.open = type == "" ? this.open : type;
      if (this.open == "login") {
        this.$refs["seeker-register"].hide();
        this.$refs["seeker-login"].show();
      }else if(this.open == "register"){
        this.$refs["seeker-register"].show();
      }else{
        this.visumeModal.open = 'exit';
        this.visumeModal.open = type;
      }
    },

    resetModal() {
      this.validation = true,
      this.auth_error = false,
      this.success_msg = false,
      this.notverified_error = false;
      this.register.name = null,
      this.register.email =  null,
      this.register.password= null,
      this.register.mobile= null,
      this.register.jobrole= [],
      this.register.experience= "",
      this.register.highest_qualification= "",
      this.register.specialisation= "",
      this.register.highest_qualification_percentage= null,
      this.register.passout_year= null,
      this.register.city= "",
      this.register.sub_location= null,
      this.register.resume= null,
      this.experience = "",
      this.isSigning = false
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.resetModal();
      this.handleSubmit();
    },
    handleSubmit() {
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    getExtension(e){
      if(e.key == "@"){
        this.showExtensions = true;
        this.selectedDomains = this.domains
      }else{
        if(this.register.email.includes("@")){
          this.showExtensions = true;
          this.selectedDomains = [];
          var split_email = this.register.email.split("@");
          this.domains.forEach(domain => {
            if(domain.includes(split_email[1])){
              this.selectedDomains.push(domain);
              if(domain == split_email[1]){
                this.showExtensions = false;
              }
            }
          });
        }else{
          this.showExtensions = false
        }
      }
    },
    setExtension(ext){
      var split_email = this.register.email.split("@");
      this.register.email = split_email[0]+"@"+ext;
      this.showExtensions = false;
    },
    hideEmailExtensions(){
      this.showExtensions = false;
      this.selectedDomains = [];
    },
    limiter(e) {
      if (e.length > 3) {
        this.jobrole_error = true;
        this.error_msg = "Select maximum 3 preferred job role";
        e.pop();
      }
    },
    showmore: function () {
      this.moreinfo = false;
      this.speci = false;
      this.more = false;
      this.register.highest_qualification = "";
      this.register.specialisation = "";
      this.register.highest_qualification_percentage = "";
      this.validation = true;
      if (this.experience < 36) {
        this.moreinfo = true;
      }
    },
    getYears(){
      var year = new Date().getFullYear();
      for(var i = year-24; i <year; i++){
        this.years.push({value:i, text:i});
      }
      for(i = year; i<=year+6; i++){
        this.years.push({value:i, text:i});
      }
    },
    getSpecification: function () {
      this.spec = [];
      this.register.specialisation = "";
      this.validation = true;
      if (this.register.highest_qualification != null) {
        this.speci = true;
      }
      this.action
        .getSpec(this.register.highest_qualification)
        .then((data) => {
          data.specifications.forEach((element) => {
            this.spec.push({
              value: element.bid,
              text: element.branch_name,
            });
          });
        });
    },
    regedu: function () {
      this.more = false;
      this.validation = true;
      if (this.register.specialisation != "") {
        this.more = true;
      }
    },
    getSubCities: function () {
      this.sublocations = [];
      this.register.sub_location = "";
      this.subCity = false;
      if (this.register.city != "") {
        this.action.getSubcity(this.register.city).then((data) => {
          if(data.sublocations){
            data.sublocations.forEach((element) => {
                this.subCity = true;
                this.sublocations.push({
                  value: element.sublocation_id,
                  text: element.sublocation_name,
                });
            });
          }
        });
      }
    },
 
    userlogin: function (e) {
      e.preventDefault();
      let src = this.src || this.$route.query.src;
      let redirect_from = this.$route.query.redirect_from;
      this.auth_error = false;
      this.success_msg = false;
      this.can_login = 0;
      this.auth_msg = '';
      this.notverified_error = false;

      if (this.validate.emailValidation(this.login.email, "email")?.val === false) {
        this.can_login++;
      }
      if (this.validate.passwordValidation(this.login.password, "password")?.val === false) {
        this.can_login++;
      }

      if(this.can_login == 0){
        // if(src === 'jobfair') {
          this.login['src'] = src;
        // }
        this.action.seekerLogin(this.login).then((res) => {
            if(res.length&&res[0].status == 1) {
                // this.notverified_error = true;
                if(res[0].message == 'Email not registered with us'){
                  this.auth_error = true;
                  this.auth_msg = "Email not registered with us";
                }
            }else if(res.status == 2){
              this.auth_error = true;
              this.auth_msg = "Password incorrect";
            }else if(res.email || res.password){
              this.auth_error = true;
              this.auth_msg = "Invalid Email or Password";
            }else if(res.message == 'Email not verified'){
              this.notverified_error = true;
            } else {
              const seeker = {token: res.token,name:res.name,visume_path:res.visume_path}
              this.$cookies.set("hire-seeker", seeker);
              this.$refs["seeker-login"].hide();
              if(this.$route.query.redirect_from) {
                window.location.href = process.env.VUE_APP_URL_WS+'/'+redirect_from;
              } else {
                location.reload();
              }
            }
          })
          .catch((err) => {
            console.log(err)
            if (err) {
              this.auth_error = true;
              this.auth_msg = "Invalid Email or Password";
            }
          });
      }else{
        console.log('empty field',this.can_login);
      }
    },
    postReg: function(e){
      e.preventDefault();
      this.can_register = 0;
      this.email_error = false;
      this.pass_error = false;
      this.mobile_error = false;
      this.mobile = null;
      this.email = null;
      this.mobile_msg = '',
      this.pass_msg = '';
      this.email_msg = '';
      this.valid = this.$refs.register_form.validate();

      if(this.valid == true){
        this.isSigning = true;
        if(this.experience == 1){
          this.register.experience = 0;
        }else{
          this.register.experience = this.experience;
        }
        this.register.src = this.src;
        this.register.redirect_from = this.redirect_from;
        this.action.seekerRegister(this.register).then((res) => {
          if (res.message == "Jobseeker registered successfully") {
            // this.seeker = res.jobseeker;
            // this.$cookies.set('seeker', this.seeker.id);
            this.seeker = res.jobseeker;
            this.email_status = res.jobseeker.status;
            this.$cookies.set('seeker', this.seeker.id);
            this.$refs['seeker-register'].hide();
            eventBus.$emit('open','visume',null,this.email_status,this.visumeModal.open)
            // this.$refs['seeker-login'].show();
            this.email_status = res.jobseeker.status;
            this.registerd = true;
          }else{
            this.isSigning = false;
            if(res.email?.length){
              this.email_error = true;
              this.email = this.register.email;
              this.email_msg = res.email[0]
            } 
            if(res.password?.length){
              this.pass_error = true;
              this.pass_msg = res.password[0]
            }
            if(res.mobile?.length){
              this.mobile_error = true;
              this.mobile = this.register.mobile;
              this.mobile_msg = res.mobile[0]
            }
          }
        }).catch((error) => console.log(error));
      }else{
        console.log("empty field", this.can_register);
      }
    },
  },
  
  watch: {
    open: {
      handler() {
        this.openModal();
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hide", () => {
      this.open = ""; //to register prop change event
    });
  }
};
</script>
<style scoped>
.modal-bg{background: #fff url(https://d22r5huxahsjvt.cloudfront.net/Assets/images/register-bg.png) no-repeat -62px top;}.lgtTxt, .lgtTxt a{color: #a4a5aa;}.h-login{width: 100%;margin-top: 20px;padding: 8px;}.error-feedback{width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;}@media only screen and (max-width: 640px){.logWrapper{padding: 0px;}}.email-dropdown-list-container{height: 0;position: absolute;width: 90%;top: 68px;}.email-dropdown-list{height: auto;position: absolute;top: 0;left: 0;width: 100%;z-index: 10000;background-color: #fff;overflow: hidden;list-style: none;margin: 0;padding: 0;box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);box-sizing: border-box;margin-top: -.9px;}.email-dropdown-item:hover{background-color: #f2f2f2;box-sizing: border-box;}
</style>
